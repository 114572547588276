<template>
  <div>
    <Header></Header>
    <router-view v-if="isRight"></router-view>
   <!-- router-view 当你的路由path 与访问的地址相符时，会将指定的组件替换该 router-view  一组router-view代表一个组件-->
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import {getUserRightFun} from '@/http/basicsApi'
import { useRoute } from 'vue-router';
import { ref } from 'vue';
export default {
    components:{
        Header,
    },
    setup() {
      const route=useRoute();
      let isRight=ref(false);
      //判断是否有权限访问此页面
      getUserRightFun(route.name,0).then(res=>{
        isRight.value=res;
        //console.log(isRight);
      })
      return{
        isRight
      }
    },
}
</script>